<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h4>
                  Add job position
                </h4>
                <router-link :to="{ name: 'dashboard' }"> Dashboard \ </router-link>
                <router-link :to="{ name: 'career' }">
                  Careers
                </router-link>
              </div>
              <!--                            <div class="col-2 ">-->
              <!--                                <router-link :to="{name:'career-process'}" class="btn btn-primary">-->
              <!--                                    <i class="fas fa-arrow-left"></i>-->
              <!--                                    Back-->
              <!--                                </router-link>-->
              <!--                            </div>-->
              <!--                            <div class="col-4">-->
              <!--                                <h4>Add Admission Process</h4>-->
              <!--                            </div>-->
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-text-field
                  outlined
                  dense
                  v-model="career.title"
                  label="Job title"
                ></v-text-field>
                <span class="text-danger" v-if="$v.career.title.$error"
                  >This information is required</span
                >
              </div>
              <div class="col-6">
                <v-text-field
                  outlined
                  dense
                  v-model="career.position"
                  label="Order"
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  v-model="career.employment_type"
                  label="Employment type"
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  v-model="career.min_qualification"
                  label="Minimum qualification"
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  v-model="career.salary_offer"
                  label="Salary offering"
                ></v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  v-model="career.no_of_vacancies"
                  label="No. of vacancies"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      dense
                      v-model="career.opened_at"
                      label="Application open date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="career.opened_at"
                    @input="
                      menu2 = false;
                      convertAdToBs();
                    "
                    header-color="primary"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-6">
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      dense
                      v-model="career.expiry_date"
                      label="Application closing date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="career.expiry_date"
                    @input="
                      menu2 = false;
                      convertAdToBs();
                    "
                    header-color="primary"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-12">
                <label for class>Job description</label>

                <ckeditor :config="editorConfig" v-model="career.description"></ckeditor>
              </div>

              <div class="col-2">
                Status
                <v-switch
                  v-model="career.is_active"
                  name="check-button"
                  :label="career.is_active ? 'Active' : 'Inactive'"
                ></v-switch>
                <span class="text-danger" v-if="$v.career.is_active.$error"
                  >This information is required</span
                >
              </div>
              <div class="col-12 mt-5">
                <div class="float-right">
                  <v-btn @click="redirectTo" class="mr-2 cancel-btn" text x-large
                    >Cancel</v-btn
                  >
                  <v-btn
                    depressed
                    :loading="isBusy"
                    @click="createOrUpdate"
                    class="text-white ml-2 btn btn-primary"
                    v-if="checkIsAccessible('career', 'create')"
                    >Save</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import CareerService from "@/core/services/career/CareerService";

const career = new CareerService();

export default {
  name: "Careers-new",
  validations: {
    career: {
      title: { required },
      is_active: { required },
    },
  },
  data() {
    return {
      career: {
        id: null,
        title: null,
        position: null,
        description: null,
        type: null,
        is_active: true,
      },
      edit: false,
      menu2: false,
      menu3: false,
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList','Image']]
      },
    };
  },
  mounted() {
    if (this.id) {
      this.getCareer(this.id);
      this.edit = true;
    }
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
  },
  methods: {
    getCareer(id) {
      career.show(id).then((response) => {
        this.career = response.data.career;
        this.career.is_active ? (this.career.is_active = true) : false;
      });
    },
    redirectTo() {
      this.$router.push({ name: "career" });
    },

    saveCareer() {
      this.isBusy = true;
      career.store(this.career).then((response) => {
        this.$snotify.success("Information added");
        this.isBusy = true;
        this.$tabs.close().then((response) => {
          this.$router.push({ name: "career" });
        });
      });
    },

    createOrUpdate() {
      this.$v.career.$touch();
      if (this.$v.career.$error) {
        setTimeout(() => {
          this.$v.career.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateCareer();
        } else {
          this.saveCareer();
        }
      }
    },
    updateCareer() {
      this.isBusy = true;
      career.update(this.career.id, this.career).then((response) => {
        this.$snotify.success("Information updated");
        this.isBusy = false;
        this.$tabs.close().then((response) => {
          this.$router.push({ name: "career" });
        });
      });
    },
    // generateFd() {
    //     let fd = new FormData();
    //     for (let key in this.career) {
    //         if (
    //             key == "image" &&
    //             this.career["image"] &&
    //             this.career["image"] != null
    //         ) {
    //             fd.append("image", this.career[key]);
    //         } else if (key == "is_active" && this.career["is_active"]) {
    //             fd.append("is_active", this.career ? 1 : 0);
    //         } else {
    //             fd.append(key, this.career[key]);
    //         }
    //     }
    //     return fd;
    // },
  },
};
</script>
